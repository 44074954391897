import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import './accordion.scss';
import { trackCustomEvent } from 'gatsby-plugin-google-analytics';

const block = 'accordion';
const Accordion = ({
  counter,
  headline,
  slug,
  content,
  className,
  hash,
  ...rest
}) => {
  const [expanded, setExpanded] = useState(false);

  const toggle = useCallback(() => {
    if (!expanded) {
      trackCustomEvent({
        category: 'Visualização de pergunta',
        action: 'Click',
        label: slug,
      });
    }
    setExpanded(!expanded);
  }, [expanded, slug]);

  useEffect(() => {
    parseInt(hash) === counter ? setExpanded(true) : setExpanded(false);
  }, [hash, counter]);

  const keyPressHandler = useCallback(
    (event) => {
      if (event.key === ' ') {
        toggle();
        event.preventDefault();
      }
    },
    [toggle]
  );

  return (
    <div className={cn(block, className)} {...rest} id={counter}>
      <div
        className={`${block}__counter`}
        onClick={toggle}
        onKeyPress={keyPressHandler}
        role="button"
        tabIndex="0"
      >
        {counter}
      </div>
      <div className={`${block}__content`}>
        <header
          className={`${block}__header`}
          onClick={toggle}
          onKeyPress={keyPressHandler}
          role="button"
          tabIndex="0"
        >
          <div className={`${block}__headline`}>{headline}</div>
          <div className={`${block}__icon`}>
            <div
              className={cn(`${block}__icon-horizontal`, {
                [`${block}__icon-horizontal--expanded`]: expanded,
              })}
            ></div>
            <div
              className={cn(`${block}__icon-vertical`, {
                [`${block}__icon-vertical--expanded`]: expanded,
              })}
            ></div>
          </div>
        </header>
        {expanded && <div className={`${block}__answer`}>{content}</div>}
      </div>
    </div>
  );
};

Accordion.propTypes = {
  counter: PropTypes.number,
  className: PropTypes.string,
  slug: PropTypes.string,
  headline: PropTypes.string,
  content: PropTypes.node,
};

Accordion.defaultProps = {};

export default Accordion;
