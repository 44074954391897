import React, { useEffect } from 'react';
import { navigate } from 'gatsby';
import MainHeader from '../../components/main-header/main-header';
import PageHeader from '../../components/page-header/page-header';
import MainFooter from '../../components/main-footer/main-footer';
import './index.scss';
import Accordion from '../../components/accordion/accordion';
import questions from '../../data/faq';
import cn from 'classnames';
import DefaultLayout from '../../components/layouts/default-layout';

const block = 'page-faq';
const Page = ({ location }) => {
  const hash = location.hash.substring(1);
  const handleBackButtonClick = () => {
    navigate(-1);
  };
  useEffect(() => {
    const element = document.getElementById(hash);
    if (hash && element) {
      const topPos = element.offsetTop;
      window.scrollTo({
        top: topPos, // scroll so that the element is at the top of the view
        behavior: 'smooth', // smooth scroll
      });
    }
  }, [hash]);

  return (
    <DefaultLayout currentPagePath={location.pathname}>
      <main className={`container--mobile ${block}`}>
        <MainHeader />
        <PageHeader
          backButtonHandler={handleBackButtonClick}
          title="FAQ - Perguntas e Respostas Frequentes"
          breadCrumbs={[]}
          hide={true}
        />
        <div className={cn(`${block}__content`, `container`)}>
          {questions.map((question, questionIndex) => {
            return (
              <Accordion
                counter={questionIndex + 1}
                headline={question.headline}
                slug={question.slug}
                content={question.answer}
                key={questionIndex}
                hash={hash}
              />
            );
          })}
        </div>
        <MainFooter />
      </main>
    </DefaultLayout>
  );
};

export default Page;
