import React from 'react';

const questions = [
  {
    slug: 'como-funciona-o-seguro-empresarial',
    headline: 'COMO FUNCIONA O SEGURO EMPRESARIAL?',
    answer: (
      <div className="accordion__answer-paragraph">
        Este seguro tem por objetivo proteger o imóvel não residencial (local de
        risco) e eventualmente o conteúdo nele inserido (objetos e equipamentos
        com valor atribuível) de riscos que possam causar danos a propriedade
        (Incêndios, raios e explosões; Alagamentos, Vendavais, Danos
        elétricos...) e suas consequências (lucros cessantes). Escritórios;
        consultórios; clínicas médicas; petshops; clínicas veterinárias;
        restaurantes; drogarias e farmácias e academias são exemplos de
        atividades aceitas nesse seguro.
        <div className="accordion__answer-paragraph">
          *Vide maiores detalhes das condições e exclusões das Condições Gerais
          e Especiais do seguro.
        </div>
      </div>
    ),
  },
  {
    slug: 'o-seguro-possui-cobertura-para-negocios-que-funcionam-em-trailer',
    headline:
      'O SEGURO POSSUI COBERTURA PARA NEGÓCIOS QUE FUNCIONAM EM TRAILER?',
    answer: (
      <div className="accordion__answer-paragraph">
        Não possui cobertura para Trailer.
      </div>
    ),
  },
  {
    slug: 'quanto-tempo-demora-para-chegar-o-e-mail-de-confirmacao-com-a-apolice',
    headline:
      'QUANTO TEMPO DEMORA PARA CHEGAR O E-MAIL DE CONFIRMAÇÃO COM A APÓLICE?',
    answer: (
      <div className="accordion__answer-paragraph">
        <strong>Prazo a confirmar com o time de emissão:</strong> Em até&nbsp;
        <strong>5</strong> dias úteis. (Sempre confirmar caixa de spam)
      </div>
    ),
  },
  {
    slug: 'quais-sao-os-telefones-para-acionar-um-sinistro-ou-um-servico-da-assistencia-do-24h',
    headline:
      'QUAIS SÃO OS TELEFONES PARA ACIONAR UM SINISTRO OU UM SERVIÇO DA ASSISTÊNCIA DO 24H?',
    answer: (
      <>
        <div className="accordion__answer-paragraph">
          <strong>SAC</strong>
          <br />
          Tel.: 4000-1246 capitais e regiões metropolitanas
          <br />
          Tel.: 0800 942 2746 demais regiões
          <br />
          Todos os dias, atendimento 24 horas
          <br />
          Tel.: 0800 778 2800 (fone exclusivo para deficientes auditivos
          <br />
          Segunda a sexta, das 9h às 18h, exceto feriados nacionais.
        </div>
        <div className="accordion__answer-paragraph">
          <strong>Aviso de Sinistro</strong>
          <br />
          Tel.: 0800 777 2746
          <br />
          Aviso de sinistro, agendamento de vistoria de sinistro e
          acompanhamento de processo de sinistro Segunda a sexta, das 9h às 18h,
          exceto feriados nacionais.
        </div>
        <div className="accordion__answer-paragraph">
          Tel.: 0800 021 8484
          <br />
          Atendimento ao Público - SUSEP
          <br />
          Processo Susep No 15414.0003402012-98 e 15414.9013852013-62
          <br />O registro desse plano na SUSEP não implica, por parte da
          autarquia, incentivo ou recomendação à sua comercialização. Ouvidoria
          Tel.: 0800 773 3103 Segunda a sexta, das 9h às 18h.
        </div>
      </>
    ),
  },
  {
    slug: 'qual-a-forma-de-pagamentoparcelamento-do-seguro',
    headline: 'QUAL A FORMA DE PAGAMENTO/PARCELAMENTO DO SEGURO?',
    answer: (
      <div className="accordion__answer-paragraph">
        Pagamento por boleto, podendo ser parcelado em até 4x sem juros e até 7x
        com juros, sendo o valor da parcela mínima de R$ 100,00.
      </div>
    ),
  },
  {
    slug: 'a-renovacao-e-automatica',
    headline: 'A RENOVAÇÃO É AUTOMÁTICA?',
    answer: (
      <div className="accordion__answer-paragraph">
        A renovação não é automática.
      </div>
    ),
  },
  {
    slug: 'como-funciona-o-aluguel-de-computadores-em-assistencia-24hrs',
    headline: 'COMO FUNCIONA O ALUGUEL DE COMPUTADORES EM ASSISTÊNCIA 24HRS?',
    answer: (
      <>
        <div className="accordion__answer-paragraph">
          Dentro do pacote de Assistência 24h, existe o serviço de:
          <br />
          LOCAÇÃO DE MICROCOMPUTADOR E IMPRESSORAS
          <br />
          Locação de Microcomputador e Impressoras se em decorrência de sinistro
          ocorrido na empresa, (Roubo ou Furto Qualificado, Incêndio, Raio,
          Explosão, Dano Elétrico, Desmoronamento, Vendaval, Granizo, Fumaça,
          Alagamento, Impacto de Veículos, Queda de Aeronaves) que resultarem
          danos nos computadores da empresa e/ou impressoras, a Assistência
          Protector providenciará o envio de equipamento substituto pelo período
          necessário ou até o limite pré-estabelecido.
          <br />A Assistência Protector locará, preferencialmente, um
          equipamento compatível com o do segurado, porém, na indisponibilidade
          deste, a Assistência Protector se reserva o direito de locar qualquer
          marca ou modelo compatível, até o limite de 30 dias por evento, por
          ano e até 2 ocorrências por ano. Para cidades sem infraestrutura, será
          oferecido o reembolso desde que previamente autorizado pela Central de
          Atendimento.
          <br />
          LIMITE: Locação de 1 microcomputador e 1 impressora por evento.
          R$500,00 (quinhentos reais) máximo de 30 dias 2 intervenções no ano.
          *Vide condições no manual de Assistência.
        </div>
        <div className="accordion__answer-paragraph">
          *Consulte maiores detalhes no manual de assistência.
        </div>
      </>
    ),
  },
  {
    slug: 'ao-que-seria-cobertura-adicional-perda-ou-pagamento-de-aluguel-a-terceiros',
    headline:
      'AO QUE SERIA COBERTURA ADICIONAL PERDA OU PAGAMENTO DE ALUGUEL A TERCEIROS?',
    answer: (
      <>
        <div className="accordion__answer-paragraph">
          A presente cobertura tem por objetivo garantir o pagamento do valor
          dos aluguéis, despesas ordinárias de condomínio, no caso de sinistro
          decorrente de incêndio, raio ou explosão, e o prédio segurado não mais
          puder ser ocupado.
          <br />
          - Caso o seguro seja contratado pelo proprietário do imóvel, mas
          ocupado por um locatário, a presente cobertura garantirá ao
          proprietário locador do imóvel o aluguel que este deixar de render,
          desde que, não conste obrigação de continuidade de pagamento dos
          aluguéis pelo locatário mesmo com a ocorrência de incêndio, raio ou
          explosão.
          <br />
          - Caso o Segurado ocupe o imóvel objeto do seguro, a presente
          cobertura garantirá o pagamento do aluguel de outro imóvel de
          características semelhantes para que nele se instale e dê continuidade
          a suas atividades.
          <br />- Caso o seguro seja contrato pelo locador do imóvel, a presente
          cobertura garantirá o pagamento do aluguel ao proprietário do imóvel.
        </div>
        <div className="accordion__answer-paragraph">
          *Vide maiores detalhes das condições e exclusões das Condições Gerais
          do seguro.
        </div>
      </>
    ),
  },
  {
    slug: 'como-posso-realizar-um-endosso-em-uma-apolice',
    headline: 'COMO POSSO REALIZAR UM ENDOSSO EM UMA APÓLICE?',
    answer: (
      <div className="accordion__answer-paragraph">
        Os pedidos de endossos são analisados internamente. Envie os detalhes
        das alterações que deseja fazer em sua apólice para o canal{' '}
        <strong>duvidasempresarial@akadseguros.com.br</strong>
      </div>
    ),
  },
  {
    slug: 'onde-encontro-as-condicoes-gerais-do-seguro',
    headline: 'ONDE ENCONTRO AS CONDIÇÕES GERAIS DO SEGURO?',
    answer: (
      <div className="accordion__answer-paragraph">
        Você pode acessar e fazer o download das Condições Gerais do Seguro
        Empresarial no rodapé do site.
      </div>
    ),
  },
  {
    slug: 'e-possivel-cotar-outros-riscos-e-limites-diferentes',
    headline: 'É POSSÍVEL COTAR OUTROS RISCOS E LIMITES DIFERENTES?',
    answer: (
      <div className="accordion__answer-paragraph">
        Sim. Caso o perfil de risco que deseja realizar uma cotação não esteja
        dentro das condições liberadas neste portal, você pode cadastrar seu
        pedido na plataforma digital para análise da subscrição. Acesse a
        plataforma&nbsp;
        <a
          href="https://digital.akadseguros.com.br"
          target="_blank"
          rel="noreferrer"
        >
          https://digital.akadseguros.com.br/
        </a>
        &nbsp; e siga o caminho: Linha Corporate &gt; Produtos &gt; Propriedade
        &gt; Compreensivo Empresarial.
      </div>
    ),
  },
  {
    slug: 'o-cliente-atrasou-o-pagamento-da-apolice-e-possivel-solicitar-a-prorrogacao-do-boleto',
    headline:
      'O CLIENTE ATRASOU O PAGAMENTO DA APÓLICE. É POSSÍVEL SOLICITAR A PRORROGAÇÃO DO BOLETO?',
    answer: (
      <div className="accordion__answer-paragraph">
        Você pode solicitar a prorrogação do boleto através do canal&nbsp;
        <a href="prorroga.boleto@akadseguros.com.br">
          prorroga.boleto@akadseguros.com.br
        </a>
        &nbsp; e será analisado internamente pela área.
      </div>
    ),
  },
];

export default questions;
